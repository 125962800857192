import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord,faTwitter } from '@fortawesome/free-brands-svg-icons'


const ModeratorDetailsContent = ({title, category, description, meta}) => {
    return (
        <div className="portfolio-details-content">

            <a href="/" className="category">{category}</a>

            <h1 className="title">{title}</h1>

            <p>{description}</p>

            <ul className="project-info">

                <li><span>Website:</span>
                    <a href={meta?.website}
                       target="_blank"
                       rel="noopener noreferrer">{meta?.website}
                    </a>
                </li>

            </ul>

            <div className="project-share">
                <span>Share:</span>
                <a href={meta?.discord}><FontAwesomeIcon icon={faDiscord} /></a>
                <a href={meta?.twitter}><FontAwesomeIcon icon={faTwitter} /></a>

            </div>

        </div>
    );
};

export default ModeratorDetailsContent;
import React, {Fragment, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import Moderators from "../../../data/moderators";
import SideHeader from "../../../components/SideHeader";
import Header from "../../../components/header/HeaderOne";
import FooterThree from "../../../components/footer/FooterThree";
import TemplateModeratingDetails from "../../../templates/moderating/details";


function useQuery() {
    const {search} = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const ValidatingDetailsPage = () => {
    const query = useQuery()
    const id = query.get("id");
    const ModeratorsParsed = Moderators?.find(Moderator => Moderator.id === parseInt(id, 10));

    return (
        <Fragment>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <TemplateModeratingDetails Moderator={ModeratorsParsed}/>
            <FooterThree position='fixed'/>
        </Fragment>
    );
};

export default ValidatingDetailsPage;
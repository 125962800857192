import React,{Fragment} from 'react';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';
import Moderator from "../../components/moderator";
import Loading from "../../components/loading";

const ModeratorThreeColumn = ({Moderators,loading}) => {
 
    const isMobile = window.innerWidth < 992;

    return (
        <Fragment>
            <div className=" section">
                <div style={{display: 'flex', maxHeight: isMobile ? 'auto' : 'calc(100vh - 200px)'}}>
                    <Scrollbar options={{suppressScrollX: true}} enable={!isMobile}>
                        <div className="section-wrap section">
                            <div className="row row-7 Moderator-column-three">
                                {Moderators.map(moderator => (
                                    <div key={moderator.id} className="col-lg-4 col-md-6 col-12 mb-15">
                                        <Moderator
                                            id={moderator.id}
                                            title={moderator.title}
                                            thumb={moderator.thumb}
                                            category={moderator.category}
                                            variant={'column'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            </div>

            {loading ? <Loading/> : null}
        </Fragment>
    );
};

export default ModeratorThreeColumn;

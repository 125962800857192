import React,{Fragment} from 'react';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';
import Validator from "../../components/validator";
import Loading from "../../components/loading";

const ValidatorThreeColumn = ({Validators,loading}) => {
 
    const isMobile = window.innerWidth < 992;

    return (
        <Fragment>
            <div className=" section">
                <div style={{display: 'flex', maxHeight: isMobile ? 'auto' : 'calc(100vh - 200px)'}}>
                    <Scrollbar options={{suppressScrollX: true}} enable={!isMobile}>
                        <div className="section-wrap section">
                            <div className="row row-7 Validator-column-three">
                                {Validators.map(validator => (
                                    <div key={validator.id} className="col-lg-4 col-md-6 col-12 mb-15">
                                        <Validator
                                            id={validator.id}
                                            title={validator.title}
                                            thumb={validator.thumb}
                                            category={validator.category}
                                            variant={'column'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            </div>

            {loading ? <Loading/> : null}
        </Fragment>
    );
};

export default ValidatorThreeColumn;

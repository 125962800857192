import React, {useState} from 'react';

// File imported
import Header from '../../components/header/HeaderOne'
import SideHeader from "../../components/SideHeader";
import InvestmentThreeColumn from "../../container/investments/InvestmentThreeColumn";
import FooterThree from "../../components/footer/FooterThree";
import InvestmentData from '../../data/investments'

const Investments = () => {
    const [count, setCount] = useState(9);
    const [loading, setLoading] = useState(false);
    const Investments = InvestmentData.slice(0, count);

    const loadMore = () => {
        setLoading(true);
        setTimeout(() => {
            setCount(count + 3);
            setLoading(false);
        }, 100);
    };

    const allItems = count === InvestmentData.length;

    return (
        <div className={'main-wrapper'}>
            <Header/>
            <SideHeader mobile={true}/>
            <InvestmentThreeColumn Investments={Investments} loading={loading}/>
            <FooterThree loadHandler={loadMore} allItems={allItems}/>
        </div>
    );
};

export default Investments;
import React from 'react';
import PropTypes from "prop-types";
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../../../container/CustomPage";
import InvestmentDetailsThumb from "./thumbnail";
import InvestmentDetailsContent from "./content";

const TemplateInvestmentDetails = ({Investment}) => {
    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <InvestmentDetailsThumb images={Investment?.previewImages}/>
                    </Col>

                    <Col lg={5}>
                        <InvestmentDetailsContent
                            title={Investment?.title}
                            category={Investment?.category}
                            description={Investment?.description}
                            meta={Investment?.meta}
                        />
                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

TemplateInvestmentDetails.propTypes = {
    Investment: PropTypes.object.isRequired,
};

export default TemplateInvestmentDetails;